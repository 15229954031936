<template>
  <div class="order-info" v-if="infoData">
    <div class="order-left">
      <div class="status">
        <div style="margin-right: 20px">
          <i class="el-icon-tickets" style="color: #ee4d2d"></i>
        </div>
        <div v-if="infoData.state == 1">待付款</div>
        <div v-if="infoData.state == 2" style="width: 100%">
          <div>待出货</div>
          <div style="font-size: 14px; margin-top: 20px">买家正在等您出货</div>
          <div style="
              width: 100%;
              background: #fafafa;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 30px 20px;
              margin-top: 10px;
              font-size: 14px;
            ">
            <div>下一步</div>
            <div>
              <el-button size="small" plain @click="cancelOrder">取消订单</el-button>
              <el-button type="primary" size="small" @click="dialogVisible = true">申请出货编号</el-button>
            </div>
          </div>
        </div>
        <div v-if="infoData.state == 3">待发货</div>
        <div v-if="infoData.state == 4">运输中</div>
        <div v-if="infoData.state == 5 || infoData.state == 8">已完成</div>
        <div v-if="infoData.state == 6">已取消</div>
        <div v-if="infoData.state == 7" style="color: #ee4d2d; width: 100%">
          <div v-if="infoData.refund_state != 3">
            买家已提出{{
              infoData.refund_data.type == 0 ? "退款" : "退货/退款"
            }}
          </div>
          <div v-else>
            <div v-if="!infoData.refund_reason">
              您已同意买家申请的{{
                infoData.refund_data.type == 0 ? "退款" : "退货/退款"
              }}
            </div>
            <div v-else>
              <div>
                您已拒绝买家申请的{{
                  infoData.refund_data.type == 0 ? "退款" : "退货/退款"
                }}
              </div>
              <div style="
                  color: #666;
                  margin-top: 10px;
                  border: 1px solid #eee;
                  padding: 20px;
                  border-radius: 4px;
                ">
                拒绝原因：{{ infoData.refund_reason }}
              </div>
            </div>
          </div>
          <div v-if="infoData.refund_state != 3" style="
              background: #fafafa;
              width: 100%;
              padding: 20px;
              color: #999;
              margin-top: 20px;
            ">
            <div>
              您需要在24小时内回应，否则买家的提案将被自动接受。您可以与买家讨论其他可能的解决方案。
            </div>
            <div style="display: flex; align-items: center; justify-content: right">
              <el-button type="primary" size="small" @click="handleAccept">接受提议</el-button>
              <el-button size="small" @click="handleRefundOrder(3)">反驳</el-button>
            </div>
          </div>

          <div v-if="infoData.refund_state != 3 && fbShow" style="margin-top: 20px">
            <el-input type="textarea" :rows="4" placeholder="请输入反驳原因" v-model="textarea" maxlength="200" show-word-limit>
            </el-input>
            <div style="text-align: right; padding-top: 10px">
              <el-button type="primary" size="small" @click="handleRefundOrder(2)">提 交</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="order">
        <div style="display: flex">
          <div style="margin-right: 20px">
            <i class="el-icon-finished" style="color: #ee4d2d"></i>
          </div>
          <div>
            <div>{{ infoData.state == 7 ? "申请单号" : "订单编号" }}</div>
            <div style="font-size: 14px; margin-top: 10px">
              {{ infoData.order_no }}
            </div>
          </div>
        </div>
        <div style="
            display: flex;
            background: #fff2ec;
            padding: 10px 10px;
            margin-left: -10px;
            margin-top: 10px;
          " v-if="infoData.state == 7">
          <div style="margin-right: 20px">
            <i class="el-icon-chat-line-square" style="color: #ee4d2d"></i>
          </div>
          <div style="">
            <div>买家退货退款原因</div>
            <div style="font-size: 14px; margin-top: 10px">
              <el-image v-for="(item, i) in infoData.refund_data.images" :key="i"
                style="width: 100px; height: 100px; margin-right: 10px" :src="item" fit="fit"></el-image>
            </div>
            <div style="font-size: 16px; margin-bottom: 6px">
              {{ infoData.refund_data.reason }}
            </div>
            <div style="font-size: 14px">
              {{ infoData.refund_data.refund_content }}
            </div>
          </div>
        </div>
        <div style="display: flex; margin-top: 20px">
          <div style="margin-right: 20px">
            <i class="el-icon-location-information" style="color: #ee4d2d"></i>
          </div>
          <div>
            <div>买家收货地址</div>
            <div style="font-size: 14px; margin-top: 10px" v-if="infoData.address">
              {{ infoData.address.name }},{{ infoData.address.mobile }},
              <br />
              {{ infoData.address.country }}{{ infoData.address.province
              }}{{ infoData.address.city }}{{ infoData.address.district }}
              {{ infoData.address.address }}
            </div>
          </div>
        </div>
        <div style="display: flex; margin-top: 20px" v-if="infoData.state != 6">
          <div style="margin-right: 20px">
            <i class="el-icon-truck" style="color: #ee4d2d"></i>
          </div>
          <div style="width: 100%">
            <div>运送资讯</div>
            <div v-if="infoData.state != 2" style="
                font-size: 14px;
                margin-top: 10px;
                padding: 10px;
                width: 100%;
              ">
              <div style="margin-bottom: 10px" v-if="infoData.express_name">
                国内物流：{{ infoData.express_name }}
                <span style="
                    background: #00bfa5;
                    color: #fff;
                    padding: 1px 2px;
                    margin-left: 10px;
                  ">{{ infoData.express_no }}</span>
              </div>
              <div v-if="infoData.freight_name">
                海外物流：{{ infoData.freight_name
                }}<span style="
                    background: #00bfa5;
                    color: #fff;
                    padding: 1px 2px;
                    margin-left: 10px;
                  ">{{ infoData.freight_no }}</span>
              </div>
              <div style="display: flex; margin-top: 10px">
                <el-image v-if="infoData.goods" style="width: 50px; height: 50px" :src="infoData.goods[0].image"
                  fit="fit">
                  <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                    style="width: 50px; height: 50px">
                    <path
                      d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                  </svg></el-image>
                <div v-if="infoData.goods" style="margin-top: 10px; margin-left: 10px">
                  Total {{ infoData.goods.length }} products
                </div>
              </div>
              <div v-if="infoData.state > 3" class="wlSeep" :style="{
                height: show ? 'auto' : '80px',
                overflow: show ? '' : 'hidden',
              }">
                <div class="float" @click="openAll">
                  {{ show ? "收起" : "展开" }}完整记录
                  <i class="el-icon-arrow-up" v-if="show"></i>
                  <i class="el-icon-arrow-down" v-else></i>
                </div>
                <div class="left">

                  <div v-for="(item, i) in 5" :key="i" style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    ">
                    <div>
                      <div class="round" :style="{
                        background: i == 0 ? '#bbebe4' : 'transparent',
                        width: i == 0 ? '24px' : '14px',
                        height: i == 0 ? '24px' : '14px',
                      }">
                        <div class="rounds" :style="{
                          background: i == 0 ? '#00bfa5' : '#e8e8e8',
                        }"></div>
                      </div>
                    </div>
                    <div class="line" :style="{ background: i == 0 ? '#bbebe4' : '#e8e8e8' }"></div>
                  </div>
                </div>
                <div class="right">

                  <div v-for="(item, i) in 5" :key="i" class="item" :style="{ height: i == 0 ? '66px' : '55px' }">
                    <div :style="{ color: i == 0 ? '#17C6BC' : '#000' }">
                      【华南国际转运仓】已彂出
                      <!-- {{ infoData.logistics.context }} -->
                    </div>
                    <div style="font-size: 12px; color: #c0bec6; margin-top: 4px">
                      {{ formatTime(infoData.deal_time, 2) }}
                      <!-- 2014-04-16 15:13:03 -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="font-size: 14px">
              <div style="margin-top: 10px">
                包裹1：{{ infoData.freight_name }}
              </div>
              <div style="display: flex; margin-top: 10px">
                <el-image style="width: 50px; height: 50px" :src="infoData.goods[0].image" fit="fit">
                  <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                    style="width: 50px; height: 50px">
                    <path
                      d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                  </svg></el-image>
                <div style="margin-top: 10px; margin-left: 10px">
                  Total {{ infoData.goods.length }} products
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payment">
        <div style="
            border-bottom: 1px dashed #e8e8e8;
            display: flex;
            padding-bottom: 20px;
            position: relative;
          ">
          <div style="
              position: absolute;
              bottom: -5px;
              right: 0;
              background: #fff;
              font-size: 12px;
              color: #8c8c8c;
              padding-left: 20px;
            " @click="openInfo">
            <span style="margin-right: 4px">{{
              infoShow ? "隐藏收入进账详情" : "进账详情"
            }}</span>
            <i class="el-icon-arrow-up" v-if="infoShow"></i>
            <i class="el-icon-arrow-down" v-else></i>
          </div>
          <div style="margin-right: 20px">
            <i class="el-icon-tickets" style="color: #ee4d2d"></i>
          </div>
          <div style="width: 100%">
            <div>付款资讯</div>
            <div class="tab-head">
              <div style="width: 5%">编号</div>
              <div style="width: 60%">商品</div>
              <div style="width: 10%; text-align: right">单价</div>
              <div style="width: 10%; text-align: right">数量</div>
              <div style="width: 10%; text-align: right">小计</div>
            </div>
            <div class="tab-list">
              <div class="tab-item" v-for="item in infoData.goods" :key="item.id">
                <div style="width: 5%">
                  <span>{{ item.id }}</span>
                </div>
                <div style="width: 60%; display: flex">
                  <el-image style="width: 70px; height: 70px; margin-right: 20px" :src="item.image" fit="fit">
                    <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                      style="width: 50px; height: 50px">
                      <path
                        d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                    </svg></el-image>
                  <div style="width: 60%">
                    {{ item.title
                    }}{{ item.difference ? ",[" + item.difference + "]" : "" }}
                  </div>
                </div>
                <div style="width: 10%; justify-content: right">
                  {{ item.price }}
                </div>
                <div style="width: 10%; justify-content: right">
                  {{ item.number }}
                </div>
                <div style="width: 10%; justify-content: right">
                  {{ item.price * item.number }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            line-height: 36px;
            font-size: 14px;
          ">
          <div style="
              border-right: 1px dashed #e8e8e8;
              width: 80%;
              text-align: right;
              padding: 10px 30px;
            ">
            <div v-if="infoShow">
              <div>商品金额</div>
              <div style="font-size: 12px; color: #8c8c8c">交易手续费</div>
            </div>
            <div>订单收入</div>
          </div>
          <div style="width: 20%; text-align: right; padding: 10px 0">
            <div v-if="infoShow">
              <div>RM{{ infoData.goods_total_money }}</div>
              <div>
                -RM{{ infoData.platform_fee ? infoData.platform_fee : 0 }}
              </div>
            </div>
            <div style="font-size: 20px; color: #ee4d2d; font-weight: 600">
              RM{{ infoData.order_total_money }}
            </div>
          </div>
        </div>
      </div>
      <div class="buyers">
        <div style="
            display: flex;
            padding-bottom: 20px;
            border-bottom: 1px solid #e8e8e8;
          ">
          <div style="margin-right: 20px">
            <i class="el-icon-coin" style="color: #ee4d2d"></i>
          </div>
          <div style="display: flex; justify-content: space-between; width: 100%">
            <div>买家实付金额</div>
            <div @click="openSubInfo">
              <i class="el-icon-arrow-up" v-if="infoSubShow"></i>
              <i class="el-icon-arrow-down" v-else></i>
            </div>
          </div>
        </div>
        <div style="display: flex; line-height: 36px; font-size: 14px">
          <div style="
              border-right: 1px dashed #e8e8e8;
              width: 80%;
              text-align: right;
              padding: 10px 30px;
            ">
            <div v-if="infoSubShow">
              <div>商品金额</div>
              <div>运费</div>
            </div>
            <div>所有买家帐款</div>
          </div>
          <div style="width: 20%; text-align: right; padding: 10px 0">
            <div v-if="infoSubShow">
              <div>RM{{ infoData.goods_total_money }}</div>
              <div>RM{{ infoData.freight_price }}</div>
            </div>
            <div style="font-size: 20px; color: #000; font-weight: 600">
              RM{{ infoData.income_total_money }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-right">
      <div class="note" v-if="infoData.state != 7">
        <div style="display: flex; color: #ee4d2d">
          <div style="margin-right: 14px">
            <i class="el-icon-document"></i>
          </div>
          <div style="width: 100%">
            <div>增加备注</div>
            <div style="margin-top: 10px">
              <el-input type="textarea" v-model="note" placeholder="注意事项不显示于买家" maxlength="200" :rows="4"
                :disabled="infoData.state == 5 || infoData.state == 8" show-word-limit></el-input>
            </div>
            <div style="display: flex; justify-content: right; margin-top: 20px"
              v-if="infoData.state != 5 && infoData.state != 8">
              <el-button size="small" type="primary" @click="save">储存</el-button>
              <el-button size="small" @click="handleBack">取消</el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="segmentation">
        <div class="sbtn">订单历史</div>
        <div v-if="infoData.state == 7" style="display: flex; padding-top: 30px">
          <!-- 退货退款 -->
          <div style="margin-right: 20px">
            <i class="el-icon-tickets" style="color: #12c0a9"></i>
          </div>
          <div>
            <div style="color: #12c0a9; font-size: 14px; margin-bottom: 6px">
              {{ infoData.refund_data.type == 0 ? "退款" : "退货退款" }}申请
            </div>
            <div style="font-size: 12px; color: #b0b0b0">
              {{ infoData.refund_data.create_time }}
            </div>
          </div>
        </div>
        <div v-if="infoData.state == 2 || infoData.state == 3" style="display: flex; padding-top: 30px">
          <!-- 待出货和待发货 -->
          <div style="margin-right: 20px">
            <i class="el-icon-tickets" style="color: #12c0a9"></i>
          </div>
          <div>
            <div style="color: #12c0a9; font-size: 14px; margin-bottom: 6px">
              新订单
            </div>
            <div style="font-size: 12px; color: #b0b0b0">
              {{ infoData.create_time }}
            </div>
          </div>
        </div>
        <div v-if="infoData.state == 5">
          <!-- 完成的 -->
          <div style="display: flex; padding-top: 30px">
            <div style="margin-right: 20px; position: relative">
              <i class="el-icon-circle-check" style="color: #12c0a9"></i>
              <div class="hline"></div>
            </div>
            <div>
              <div style="color: #12c0a9; font-size: 14px; margin-bottom: 6px">
                订单已完成
              </div>
              <div style="font-size: 12px; color: #b0b0b0">
                {{ infoData.take_time }}
              </div>
            </div>
          </div>
          <div style="display: flex; padding-top: 30px">
            <div style="margin-right: 20px; position: relative">
              <i class="el-icon-money" style="color: #6c6c6c"></i>
              <div class="hline" style="height: 64px"></div>
            </div>
            <div>
              <div style="color: #6c6c6c; font-size: 14px; margin-bottom: 6px">
                买家已完成订单
              </div>
              <div style="font-size: 14px; color: #6c6c6c">
                订单已确认，拨款进行中
              </div>
              <div style="font-size: 12px; color: #b0b0b0">
                {{ infoData.take_time }}
              </div>
            </div>
          </div>
          <div style="display: flex; padding-top: 30px">
            <div style="margin-right: 20px; position: relative">
              <i class="el-icon-tickets" style="color: #6c6c6c"></i>
            </div>
            <div>
              <div style="color: #6c6c6c; font-size: 14px; margin-bottom: 6px">
                新订单
              </div>
              <div style="font-size: 12px; color: #b0b0b0">
                {{ infoData.create_time }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="infoData.state == 6">
          <!-- 取消订单 -->
          <div style="display: flex; padding-top: 30px">
            <div style="margin-right: 20px; position: relative">
              <i class="el-icon-circle-close" style="color: #12c0a9"></i>
              <div class="hline"></div>
            </div>
            <div>
              <div style="color: #12c0a9; font-size: 14px; margin-bottom: 6px">
                订单已取消
              </div>
              <div style="font-size: 12px; color: #b0b0b0">
                {{ infoData.cancel_time }}
              </div>
            </div>
          </div>
          <div style="display: flex; padding-top: 30px">
            <div style="margin-right: 20px; position: relative">
              <i class="el-icon-circle-close" style="color: #6c6c6c"></i>
              <div class="hline"></div>
            </div>
            <div>
              <div style="color: #6c6c6c; font-size: 14px; margin-bottom: 6px">
                订单已取消
              </div>
              <div style="font-size: 12px; color: #b0b0b0">
                {{ infoData.cancel_time }}
              </div>
            </div>
          </div>
          <div style="display: flex; padding-top: 30px">
            <div style="margin-right: 20px; position: relative">
              <i class="el-icon-tickets" style="color: #6c6c6c"></i>
            </div>
            <div>
              <div style="color: #6c6c6c; font-size: 14px; margin-bottom: 6px">
                新订单
              </div>
              <div style="font-size: 12px; color: #b0b0b0">
                {{ infoData.create_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 申请出货编号 -->
    <el-dialog :title="'出货' + infoData.ship_number" :visible.sync="dialogVisible" width="600px"
      :before-close="handleClose">
      <div style="
          display: flex;
          background: #fafafa;
          padding: 20px;
          border-radius: 4px;
          margin-top: -10px;
        ">
        <div style="margin-right: 10px">
          <i class="el-icon-location-information"></i>
        </div>
        <div style="color: #949494; font-size: 14px" v-if="infoData.address">
          <div>
            {{ infoData.address.name }}，(+86)
            {{ infoData.address.mobile }}
          </div>
          <div style="margin-top: 8px">
            {{ infoData.address.province }}
            {{ infoData.address.city }}
            {{ infoData.address.district }}
            {{ infoData.address.address }}，{{ infoData.address.country }}，{{
              infoData.address.zip_code
            }}
          </div>
        </div>
      </div>
      <div>
        <div style="font-size: 14px; margin: 20px 0 10px 0">物流选项</div>
        <div style="margin-bottom: 20px">
          <el-select size="mini" v-model="shipment.logisticsId" placeholder="请选择">
            <el-option v-for="item in logisticsList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>
          <div style="font-size: 14px; margin-bottom: 10px">
            取货编号/交货便代码
          </div>
          <div>
            <el-input size="mini" v-model="shipment.code" placeholder="(选填)"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="shipmentConfirm">出 货</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOrderInfo,
  orderAction,
  cancelOrder,
  refundOrder,
} from "@/api/order.js";
import { applyShipNumber } from "@/api/logistics.js";
import { logisticsList } from "@/api/common.js";
import env from "@/settings/env.js";
export default {
  data() {
    return {
      baseURL: env.fileUrlPrefix,
      show: false,
      note: "",
      infoData: {},
      infoShow: false,
      infoSubShow: false,
      dialogVisible: false,
      logisticsList: "",
      shipment: {
        logisticsId: "",
        code: "",
        id: "",
      },
      fbShow: false,
      textarea: "",
    };
  },
  methods: {
    getInfo() {
      getOrderInfo({
        order_id: localStorage.SHOPORDERID,
      }).then((res) => {
        if (res.code == 1) {
          this.infoData = res.data;
          this.infoData.freight_price = 0;
          this.infoData.goods.forEach((item) => {
            this.infoData.freight_price = (
              parseFloat(this.infoData.freight_price) +
              parseFloat(item.freight_price)
            ).toFixed(2);
          });
          if (res.data.state == 5 || res.data.state == 8) {
            this.note = res.data.remarks;
          }
          if (res.data.state == 7) {
            if (this.infoData.refund_data.images) {
              this.infoData.refund_data.images =
                this.infoData.refund_data.images.split(",");
            }
          }
          if (res.data.take_time) {
            res.data.take_time = this.format(res.data.take_time * 1000);
          }
          if (res.data.cancel_time) {
            res.data.cancel_time = this.format(res.data.cancel_time * 1000);
          }
        }
      });
    },
    getLogisticesList() {
      logisticsList().then((res) => {
        if (res.code == 1) {
          this.logisticsList = res.data;
          this.shipment.logisticsId = this.logisticsList[0].id;
        }
      });
    },
    shipmentConfirm() {
      let logisticsName = "";
      this.logisticsList.forEach((item) => {
        if (this.shipment.logisticsId == item.id) {
          logisticsName = item.name;
        }
      });
      applyShipNumber({
        id: this.infoData.id,
        fright_id: this.shipment.logisticsId,
        fright_name: logisticsName,
        shipment_number: this.infoData.ship_number,
        pickup_num: this.shipment.code,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.dialogVisible = false;
          this.getInfo();
        }
      });
    },
    openAll() {
      this.show = this.show ? false : true;
    },
    openInfo() {
      this.infoShow = this.infoShow ? false : true;
    },
    openSubInfo() {
      this.infoSubShow = this.infoSubShow ? false : true;
    },
    save() {
      orderAction({
        order_id: localStorage.SHOPORDERID,
        remark: this.note,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.shipment = {
        logisticsId: this.logisticsList[0].id,
        code: "",
        id: "",
      };
    },
    cancelOrder() {
      cancelOrder({
        order_id: this.infoData.id,
        reason: "12",
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.getInfo();
        }
      });
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleRefundOrder(type) {
      if (type == 1) {
        this.textarea = "";
      }
      if (type == 1 || type == 2) {
        refundOrder({
          refund_id: this.infoData.refund_data.id,
          status: type,
          refund_reason: this.textarea,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("操作成功");
            this.getInfo();
          }
        });
      } else {
        this.fbShow = this.fbShow ? false : true;
      }
    },
    handleAccept() {
      this.$confirm(
        "您确定选择接受建议吗？并通知买方将产品退还至Shopee Warehouse，一旦产品到达，Shopee Warehouse将验证请求。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.handleRefundOrder(1);
        })
        .catch(() => { });
    },
  },
  created() {
    this.getInfo();
    this.getLogisticesList();
  },
};
</script>
<style lang="less" >
.order-info {
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;

  .order-left {
    width: 80%;
    margin-right: 20px;
    font-size: 16px;

    .status {
      display: flex;
      background: #fff;
      padding: 30px;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.7);
    }

    .order {
      background: #fff;
      padding: 30px;
      border-radius: 4px;
      margin-top: 20px;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.7);

      .wlSeep {
        background: #fafafa;
        padding: 20px;
        display: flex;
        margin-top: 10px;
        position: relative;

        .float {
          position: absolute;
          right: 50px;
          top: 30px;
          color: #00bfa5;
          cursor: pointer;
        }

        .left {
          margin-right: 20px;

          .round {
            // width: 24px;
            height: 24px;
            // background: #bbebe4;
            border-radius: 50%;
            position: relative;

            .rounds {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              // background: #00bfa5;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              z-index: 1;
            }
          }

          .line {
            width: 2px;
            height: 42px;
            // margin-top: -20px;
            // background: #e8e8e8;
          }
        }

        .right {
          .item {
            div {
              font-size: 16px;
            }
          }
        }
      }
    }

    .payment {
      background: #fff;
      padding: 30px;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.7);
      margin-top: 20px;
      width: 100%;

      .tab-head {
        padding: 10px;
        background: #fafafa;
        width: 100%;
        display: flex;
        font-size: 12px;
        color: #8c8c8c;
        margin-top: 10px;

        div {
          // margin-right: 20px;
        }
      }

      .tab-list {
        .tab-item {
          display: flex;
          font-size: 12px;
          padding: 10px;

          div {
            display: flex;
            align-items: center;
            // margin-right: 20px;
          }
        }
      }
    }

    .buyers {
      background: #fff;
      margin-top: 20px;
      padding: 30px;
      border-radius: 4px;
    }
  }

  .order-right {
    width: 20%;

    .note {
      width: 100%;
      padding: 20px;
      background: #fff;
      font-size: 14px;

      .el-textarea__inner {
        border: 1px solid #fff;
      }
    }

    .segmentation {
      height: 2px;
      width: 100%;
      background: #e8e8e8;
      margin-top: 20px;
      position: relative;

      .sbtn {
        width: 80px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        color: #bababa;
        font-size: 14px;
        background: #f9f9f9;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }

      .hline {
        position: absolute;
        width: 1px;
        height: 44px;
        background: #cec0bb;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
</style>